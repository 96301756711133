/*******************************
        Customised Views
*******************************/
 
// import { FullItemView } from './components';

import WmcCoachView from './customizations/components/Views/WmcCoachView';
import WmcListView from './customizations/components/Views/WmcListView';
import ListingView from './customizations/components/Views/ListingView';
import customBlocks from './customizations/components/manage/Blocks/customBlocks';

import Edit from './customizations/components/manage/Blocks/LinkImage/Edit';
import View from './customizations/components/manage/Blocks/LinkImage/View';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';


export default function applyConfig(config) {
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      wmccoach_view: WmcCoachView,
      listing_view: ListingView,
    },
    layoutViews: {
      ...config.views.layoutViews,
      wmccoach_view: WmcCoachView,
      listing_view: ListingView,
    },
  };
  config.blocks = {
    ...config.blocks,
    blocksConfig: { 
      ...config.blocks.blocksConfig,
      ...customBlocks},
  }
  return config;
}
