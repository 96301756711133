import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { getBaseUrl } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

import { Container, Icon, Image, Button } from 'semantic-ui-react';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { flattenToAppURL } from '@plone/volto/helpers';
import moment from 'moment';

const SummaryTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  const { settings } = config;

return (
    <>
      <div className="items summaryplus">
       {items.map((item) => (
          <div key={item['@id']} className="registered listing-item ui segment">
             <ConditionalLink item={item} condition={!isEditMode}>
              {!item.image_field && <img src={DefaultImageSVG} alt="" />}
              {item.image_field && (
                <img
                  src={flattenToAppURL(
                    `${item['@id']}/@@images/${item.image_field}/preview`,
                  )}
                  alt={item.title}
                />
              )}
              <div className="listing-body">
                <h3>{item.title ? item.title : item.id}</h3>
                <p><strong>{item.review_state}</strong>
                    <span> since {item.start && moment(item.start).format("Do MMMM YYYY")}</span>
                </p>
                <p><strong>In:</strong> {item.location}</p>
                <Button compact animated='fade' size='medium' color='blue'>
                    <Button.Content visible>More Information</Button.Content>
                    <Button.Content hidden>on this Coach <Icon name='arrow right' /></Button.Content>
                </Button>
              </div>
            </ConditionalLink>
        </div>
      ))}
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};

SummaryTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

/**
{
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.object,
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};
 */


export default SummaryTemplate;
