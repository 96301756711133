/**
 * WmcCoaching individual view component.
 * @module components/Views/WmccoachItemView
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DefaultView, Field } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { Link } from 'react-router-dom';
import { getBaseUrl } from '@plone/volto/helpers';
import moment from 'moment';
import { 
    Container, 
    Header, 
    Icon, 
    Segment, 
    Image, 
    Button, 
    Label 
} from 'semantic-ui-react';

import {
    Helmet,
    difference,
    flattenToAppURL,
    flattenHTMLToAppURL
} from '@plone/volto/helpers';

/**
 * WmcCoach view component class.
 * @function WmcCoachView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */

const WmcCoachView = props=> {
    const { content } = props;

    return (
      <>
        <Container id="individual_coach_info">
          <Helmet title={content.title} />
          <h1 className="documentFirstHeading">
            <span className="coach_individual">Weight Management Coach : {content.first_name} {content.last_name}</span>
          </h1>
          <Segment clearing>
            <h2 className="firstname">{content.first_name} {content.last_name}</h2>
            <Image 
              src={flattenToAppURL(content.image.scales.preview.download)}
              size="small"
              floated="right"
              alt={content.image_caption}
              avatar
            />
            <div className="large-screen">
                <p><strong className="pad_right">Current Status:</strong>
                    <span>{content.review_state}</span>
                </p>                   
                <p className="reg_date">
                  <strong className="pad_right">First Registered:</strong>
                  <span>{content.reg_date && moment(content.reg_date).format("Do MMMM YYYY")}</span>
                </p>
            </div>
            <div className="small-screen">
                <p><strong className="pad_right">Current Status:</strong>
                    <div className="new-line">{content.review_state}</div>
                </p>                   
                <p className="reg_date">
                  <strong className="pad_right">First Registered:</strong>
                  <div className="new-line">{content.reg_date && moment(content.reg_date).format("Do MMMM YYYY")}</div>
                </p>
            </div> 
            <p className="location"><strong className="pad_right">Location:</strong> 
              {content.reglocation ? content.reglocation.title : content.title}</p>
            <p className="district"><strong className="pad_right">District:</strong> 
              {content.regdistrict ? content.regdistrict.title : content.title}</p>
            <div className="coachContact">
              <p className="phone"> 
                <a href={`tel:${content.reg_phone}`}>
                  <Icon name="phone" />
                  <span>Phone me on: {content.reg_phone}</span>
                </a>
              </p>
              <p className="email"> 
                <a href={`mailto:${content.reg_email}`}>
                  <Icon name="mail" />
                  <span>Email me</span>
                </a>
              </p>
            </div>    
            <Link to={`${getBaseUrl(content.id)}/free-consult`}>
              <Button floated="right" animated='fade' size='big' color='blue'>
               <Button.Content visible aria-pressed="false"> Request a FREE Consultation </Button.Content>
               <Button.Content hidden  aria-pressed="false"> Click here to apply </Button.Content>
              </Button>
            </Link>
          </Segment>

        </Container>
    </>
    );
};

/**
 *         <Segment clearing>
 *           <span className="client_feedback"></span>
 *         </Segment>
 */

/**
WmcCoachView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};
 */

WmcCoachView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.object,
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default WmcCoachView
