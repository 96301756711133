import CustomBlockView from './CustomBlock/View';
import CustomBlockEdit from './CustomBlock/Edit';
import LinkImageView from './LinkImage/View';
import LinkImageEdit from './LinkImage/Edit';

import ImageSettingsSchema from '@plone/volto/components/manage/Blocks/Image/Schema';
import heroSVG from '@plone/volto/icons/hero.svg';
import icon from '@plone/volto/icons/list-bullet.svg';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  linkimage: {
    id: 'linkimage',
    defaultMessage: 'Linked Image',
  },
});

const customBlocks = {
  linkimage: {
    id: 'linkimage',
    title: 'Linked Image',
    icon: heroSVG,
    group: 'common',
    view: LinkImageView,
    edit: LinkImageEdit,
    schema: ImageSettingsSchema,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  // lindatest: {
  //  id: 'lindatest',
  //  title: 'Test Image',
  //  edit: CustomBlockEdit,
  //  view: CustomBlockView,
  //  icon: icon,
  //  group: 'text',
  //  restricted: false,
  //  mostUsed: false,
  //  sidebarTab: 1,
  //  security: {
  //    addPermission: [],
  //    view: [],
  //  },
  // },
}
export default customBlocks;
