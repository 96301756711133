export const DataSchema = (props) => {
  return {
    title: 'Link Image Block',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'bg_image', 'href', 'openLinkInNewTab'],
      },
    ],
    properties: {
      href: {
        title: 'Link',
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Image', 'Title'],
        allowExternals: true,
      },
      bg_image: {
        title: 'Image',
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
      },
      title: {
        title: 'Title',
      },
      openLinkInNewTab: {
        title: 'Open Link in New Tab',
        type: 'boolean',
      },
    },
    required: [],
  };
};
