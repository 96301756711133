/**
 * WmcCoaching free consultation request.
 * @module components/manage/Forms/ConsultForm
 */

import React, {Component} from 'react';
import { FormErrors } from './FormErrors';
import './Form.css';
import {
    Helmet,
    difference,
    FormValidation,
    messages,
} from '@plone/volto/helpers';

import PropTypes from 'prop-types';
import { DefaultView, Field } from '@plone/volto/components';
import { 
    Container, 
    Segment, 
    Button,
    Label, 
    Form, 
    Checkbox, 
    Input, 
    TextArea, 
    Message 
} from 'semantic-ui-react';

import config from '@plone/volto/registry';

const genderOptions = [
    { key: 'm', text: 'Male', value: 'male' },
    { key: 'f', text: 'Female', value: 'female' },
    { key: 'o', text: 'Other', value: 'other' },
]

class ConsultForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {}
  
  handleSubmit(event) {
    alert('Thank you for your email, I will be in contact soon');
    event.preventDefault();
    this.setState({ email: '',first: '',last: '', gender: '', comment: '', agree: '' })
  }
    
  render() {
    const { value } = this.props
    const { email, first, last, gender, comment, agree } = this.state

    return (
      <Container>
        <Segment clearing>
            <Form method="post" onSubmit={this.handleSubmit}>
                <Form.Group widths='equal'>
                  <Form.Input required
                      id='form-input-control-first-name'
                      name='First name'
                      value={first}
                      label='Your First Name'
                      placeholder='First Name'
                  />
                  <Form.Input required
                      id='form-input-control-last-name'
                      name='Last name'
                      value={last}
                      label='Your Last Name'
                      placeholder='Last Name'
                  />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Select
                      name='Gender'
                      value={gender}
                      options={genderOptions}
                      label={{ children: 'Gender', htmlFor: 'form-select-control-gender' }}
                      placeholder='Gender'
                      search
                      searchInput={{ id: 'form-select-control-gender' }}
                      width={3}
                  />
                  <Form.Input required
                      id='form-input-control-email'
                      name='email'
                      value={email}
                      label='Email'
                      type='email'
                      placeholder='Your contact email address'
                  />
                </Form.Group>
                <Form.TextArea
                      id='form-textarea-control-opinion'
                      name='comment'
                      value={comment}
                      label='Comments'
                      placeholder='Do you have any questions or comments'
                />
                <Form.Checkbox required
                      name='agree'
                      value={agree}
                     label="I have read & agree to your T's and C's"
                />
                <Button content='Submit' value='Submit' type='submit' color='blue' aria-pressed="false" />
            </Form>
        </Segment>
      </Container>
    )
  }
}

export default ConsultForm;
