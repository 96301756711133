/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useGoogleAnalytics } from 'volto-google-analytics'

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  useGoogleAnalytics();

  let content = (  
  <Segment
    role="contentinfo"
    vertical
    padded
    inverted
    color="grey"
    textAlign="center"
    id="footer"
  >
    <Container>
      <Segment basic inverted color="grey" className="discreet">
        <FormattedMessage
          id="WMC - {whois} is {copyright} 2021-{current_year}."
          defaultMessage="WMC - {whois} is {copyright} 2021-{current_year}."
          values={{
            whois: (
              <FormattedMessage
                id="Weight Management Coach Register"
                defaultMessage="Weight Management Coach Register"
                values={{ reg: <sup>®</sup> }}
              />
            ),
            copyright: (
              <abbr title={intl.formatMessage(messages.copyright)}>©</abbr>
            ),
            current_year: new Date().getFullYear(),
            nzihf: (
              <a className="item" href="https://www.nzihf.co.nz/">
                <FormattedMessage
                  id="NZ Institue of Health and Fitness"
                  defaultMessage="NZ Institue of Health and Fitness"
                />
              </a>
            ),
          }}
        />
      </Segment>
      <List horizontal inverted>
        {/* wrap in div for a11y reasons: listitem role cannot be on the <a> element directly */}
        {/* <div role="listitem" className="item">
          <Link className="item" to="/sitemap">
            <FormattedMessage id="Site Map" defaultMessage="Site Map" />
          </Link>
        </div>
        <div role="listitem" className="item">
          <Link className="item" to="/accesibility-info">
            <FormattedMessage
              id="Accessibility"
              defaultMessage="Accessibility"
            />
          </Link>
        </div>
        <div role="listitem" className="item">
          <Link className="item" to="/contact-form">
            <FormattedMessage id="Contact" defaultMessage="Contact" />
          </Link>
        </div> */}
        <Segment basic className="ui discreet segment">
          <a className="item" href="https://www.thevirtual.co.nz">
            <FormattedMessage
              id="Hosted by theVirtual Ltd"
              defaultMessage="Hosted by theVirtual Ltd"
            />
          </a>
        </Segment>
      </List>
    </Container>
  </Segment>

  );
    return content;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
